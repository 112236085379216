'use client';

// Libraries
import { useTranslation } from 'next-export-i18n';
import Head from 'next/head';
import { useMemo } from 'react';

export default function Metadata({
  canonical,
  title = '',
  description = '',
  image = '',
  imageType = 'image/png',
  imageWidth = 600,
  imageHeight = 400,
}) {
  const { t } = useTranslation();
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <Head>
      <title>
        {title
          ? t(title) + ' | ' + t('common.meta.title')
          : t('common.meta.title')}
      </title>
      <meta
        name="description"
        content={t(description) || t('common.meta.description')}
      />
      <meta property="og:site_name" content={t('common.product-name')} />
      <meta
        property="og:title"
        content={
          title
            ? t(title) + ' | ' + t('common.meta.title')
            : t('common.meta.title')
        }
      />
      <meta
        property="og:image"
        content={
          image
            ? process.env.hostName + image
            : process.env.hostName + '/assets/img/di-cloud/model.png'
        }
      />
      <meta
        property="og:image:alt"
        content={
          title
            ? t(title) + ' | ' + t('common.meta.title')
            : t('common.meta.title')
        }
      />
      <meta property="og:image:type" content={imageType} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="og:url" content={canonical} />
      <meta
        property="og:description"
        content={
          t(description)
            ? t(description) + ' ' + t('common.meta.description')
            : t('common.meta.description')
        }
      />
      <meta property="og:type" content="website" />
      <meta name="keywords" content={t('common.meta.keywords')} />
      <meta name="news_keywords" content={t('common.meta.keywords')} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=no"
      />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta
        name="copyright"
        content={t('common.footer.copy-right', { year: currentYear })}
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
      <meta
        name="apple-mobile-web-app-title"
        content={t('common.product-name')}
      />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="abstract" content={t('common.meta.abstract')} />
      <meta name="author" content={t('common.company-name')} />
      <link rel="icon" href="/assets/img/favicon.ico" type="image/x-icon" />
      <link
        rel="shortcut icon"
        sizes="32x32"
        href="/assets/img/favicon-32x32.png"
        type="image/png"
      />
      <link
        rel="shortcut icon"
        sizes="16x16"
        href="/assets/img/favicon-16x16.png"
        type="image/png"
      />
      <link
        rel="apple-touch-startup-image"
        href="/assets/img/apple-touch-icon.png"
        type="image/png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/assets/img/apple-touch-icon.png"
        type="image/png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="512x512"
        href="/assets/img/android-chrome-512x512.png"
        type="image/png"
      />
      <link
        rel="apple-touch-icon-precomposed"
        sizes="192x192"
        href="/assets/img/android-chrome-192x192.png"
        type="image/png"
      />
      <link rel="manifest" href="/assets/img/site.webmanifest" />
      <link rel="canonical" href={canonical} />
    </Head>
  );
}
