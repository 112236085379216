'use client';

// Libraries
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Stack,
  Toast,
} from 'react-bootstrap';
import * as formik from 'formik';
import * as yup from 'yup';
import { useLanguageQuery, useTranslation } from 'next-export-i18n';
import { ArrowForward, ChatOutlined } from '@mui/icons-material';
import {
  Backdrop,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useReCaptcha } from 'next-recaptcha-v3';

// Components
import UserIcon from '@/components/icons/RequestDemo/User';
import HomeIcon from '@/components/icons/RequestDemo/Home';
import EmailIcon from '@/components/icons/RequestDemo/Email';
import PhoneIcon from '@/components/icons/RequestDemo/Phone';

// Reducers
import { useSendRegisterDemoEmailMutation } from '@/slices/emailSlice';

export default function RequestDemo() {
  const { t } = useTranslation();
  const { Formik } = formik;
  const [
    sendRegisterDemoEmail,
    { data: response, isLoading, isError, isSuccess },
  ] = useSendRegisterDemoEmailMutation();
  const [query] = useLanguageQuery();
  const currentLanguage = query && query.lang;
  const [openAlertForm, setOpenAlertForm] = useState(false);
  const isMobile = useMediaQuery(`(max-width: 767.98px)`);
  const { executeRecaptcha } = useReCaptcha();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const schema = yup.object().shape({
    fullName: yup
      .string()
      .min(
        5,
        t('common.message.min', {
          fieldName: t('content.common.contact-us.place-holder.full-name'),
          length: '5',
        })
      )
      .max(
        255,
        t('common.message.max', {
          fieldName: t('content.common.contact-us.place-holder.full-name'),
          length: '255',
        })
      )
      .required(
        t('common.message.required', {
          fieldName: t('content.common.contact-us.place-holder.full-name'),
        })
      ),
    company: yup
      .string()
      .min(
        5,
        t('common.message.min', {
          fieldName: t('content.common.contact-us.place-holder.company'),
          length: '5',
        })
      )
      .max(
        255,
        t('common.message.max', {
          fieldName: t('content.common.contact-us.place-holder.company'),
          length: '255',
        })
      )
      .required(
        t('common.message.required', {
          fieldName: t('content.common.contact-us.place-holder.company'),
        })
      ),
    email: yup
      .string()
      .email(
        t('common.message.invalid', {
          fieldName: t('content.common.contact-us.place-holder.email'),
        })
      )
      .required(
        t('common.message.required', {
          fieldName: t('content.common.contact-us.place-holder.email'),
        })
      ),
    phoneNumber: yup
      .string()
      .min(
        10,
        t('common.message.min', {
          fieldName: t('content.common.contact-us.place-holder.phone-number'),
          length: '10',
        })
      )
      .required(
        t('common.message.required', {
          fieldName: t('content.common.contact-us.place-holder.phone-number'),
        })
      ),
    content: yup
      .string()
      .min(
        10,
        t('common.message.min', {
          fieldName: t('content.common.contact-us.place-holder.content'),
          length: '10',
        })
      )
      .max(
        1000,
        t('common.message.max', {
          fieldName: t('content.common.contact-us.place-holder.content'),
          length: '1000',
        })
      )
      .required(
        t('common.message.required', {
          fieldName: t('content.common.contact-us.place-holder.content'),
        })
      ),
  });

  const handleCloseBackdrop = useCallback(() => {
    setOpenBackdrop(false);
  }, [setOpenBackdrop]);
  const handleOpenBackdrop = useCallback(() => {
    setOpenBackdrop(true);
  }, [setOpenBackdrop]);

  const handleSubmitContactUsForm = useCallback(
    async (values) => {
      try {
        handleOpenBackdrop();
        setOpenAlertForm(false);
        const token = await executeRecaptcha('request_demo_dicloud');
        await sendRegisterDemoEmail({
          title: t('content.common.contact-us.send-email.title.register'),
          fullName: values.fullName,
          company: values.company,
          email: values.email,
          phoneNumber: values.phoneNumber,
          content: values.content,
          token,
        });
      } catch (ex) {
        handleCloseBackdrop();
      }
    },
    [
      executeRecaptcha,
      sendRegisterDemoEmail,
      t,
      handleCloseBackdrop,
      handleOpenBackdrop,
    ]
  );

  useEffect(() => {
    if (isSuccess || isError) {
      setOpenAlertForm(true);
      handleCloseBackdrop();
    }
  }, [handleCloseBackdrop, isSuccess, isError]);

  return (
    <section className="contact-us-modal">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}>
        <Stack direction="horizontal" gap={2}>
          <CircularProgress color="inherit" />
          {t('common.button.registering')}
        </Stack>
      </Backdrop>
      <Container className="pt-1 pb-1">
        <Row className="pb-3">
          <Col>
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              fontWeight={700}
              className="caption text-uppercase pb-4 pt-4">
              {t('content.common.contact-us-modal.caption')}
            </Typography>
          </Col>
        </Row>
        <Row>
          <Col>
            <Toast
              onClose={() => setOpenAlertForm(false)}
              show={openAlertForm}
              delay={10000}
              autohide={isSuccess}
              className="mb-3 w-100"
              bg={isSuccess ? 'success' : 'danger'}>
              <Toast.Body className="text-white">
                {isSuccess &&
                  response &&
                  response.success &&
                  t('content.common.contact-us.send-mail-success')}
                {isError && t('content.common.contact-us.send-mail-error')}
              </Toast.Body>
            </Toast>
            <Formik
              initialValues={{
                fullName: '',
                company: '',
                email: '',
                phoneNumber: '',
                content: '',
              }}
              validationSchema={schema}
              onSubmit={handleSubmitContactUsForm}>
              {({ handleChange, handleSubmit, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group as={Col} className="mb-3 position-relative">
                    <InputGroup>
                      <InputGroup.Text>
                        <UserIcon />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={values.fullName}
                        name="fullName"
                        onChange={handleChange}
                        placeholder={t(
                          'content.common.contact-us.place-holder.full-name'
                        )}
                        isInvalid={!!errors.fullName}
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.fullName}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3 position-relative">
                    <InputGroup>
                      <InputGroup.Text>
                        <HomeIcon />
                      </InputGroup.Text>
                      <Form.Control
                        type="text"
                        value={values.company}
                        name="company"
                        onChange={handleChange}
                        placeholder={t(
                          'content.common.contact-us.place-holder.company'
                        )}
                        isInvalid={!!errors.company}
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.company}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3 position-relative">
                    <InputGroup>
                      <InputGroup.Text>
                        <EmailIcon />
                      </InputGroup.Text>
                      <Form.Control
                        value={values.email}
                        name="email"
                        onChange={handleChange}
                        type="email"
                        placeholder={t(
                          'content.common.contact-us.place-holder.email'
                        )}
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3 position-relative">
                    <InputGroup>
                      <InputGroup.Text>
                        <PhoneIcon />
                      </InputGroup.Text>
                      <Form.Control
                        value={values.phoneNumber}
                        name="phoneNumber"
                        onChange={handleChange}
                        type="tel"
                        placeholder={t(
                          'content.common.contact-us.place-holder.phone-number'
                        )}
                        isInvalid={!!errors.phoneNumber}
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.phoneNumber}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col} className="mb-3 position-relative">
                    <InputGroup>
                      <InputGroup.Text>
                        <ChatOutlined />
                      </InputGroup.Text>
                      <Form.Control
                        as="textarea"
                        value={values.content}
                        name="content"
                        onChange={handleChange}
                        placeholder={t(
                          'content.common.contact-us.place-holder.content'
                        )}
                        isInvalid={!!errors.content}
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errors.content}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Button
                      type="submit"
                      disabled={isLoading}
                      className="mb-3 w-100 btn-contact-us border-0 rounded-3 d-flex justify-content-center">
                      <ArrowForward />
                      {isLoading
                        ? t('common.button.registering')
                        : t('common.button.register')}
                    </Button>
                  </Form.Group>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
