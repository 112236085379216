'use client';

// Libraries
import { Container, Modal, ThemeProvider } from 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';
import { useLanguageQuery } from 'next-export-i18n';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

// Customize
import Metadata from '@/components/Metadata';
import GoogleAnalytics from '@/components/GoogleAnalytics';
import RecaptchaProvider from '@/components/RecaptchaProvider';
import ContactUsModal from '@/components/ContactUsModal';

// Variables
const Footer = dynamic(() => import('@/components/Footer'), { ssr: false });
const Header = dynamic(() => import('@/components/Header'));
const inter = Inter({ subsets: ['latin'], variable: '--font-inter' });

library.add(fas, fab);

export default function LayoutHeaderNormal({
  children,
  canonical,
  title,
  description,
  image,
  imageType,
  imageWidth,
  imageHeight,
}) {
  const [query] = useLanguageQuery();
  const currentLanguage = query && query.lang;
  const { asPath } = useRouter();
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    const hash = asPath.split('#')[1];
    if (hash === 'register-demo') {
      setIsShowModal(true);
    }
  }, [asPath]);

  return (
    <RecaptchaProvider useEnterprise language={currentLanguage}>
      <Metadata
        canonical={canonical}
        title={title}
        description={description}
        image={image}
        imageType={imageType}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
      />
      <ThemeProvider>
        <Container
          fluid
          className={inter.className + ' top-50'}
          data-bs-theme="dicloud">
          <ContactUsModal
            isShowModal={isShowModal}
            setIsShowModal={setIsShowModal}
          />
          <Header />
          <GoogleAnalytics />
          <main className="fixed-header">{children}</main>
          <Footer />
        </Container>
      </ThemeProvider>
    </RecaptchaProvider>
  );
}
